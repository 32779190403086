import React, { useState } from 'react';
import angola from '../../assets/angola.svg';
import portugal from '../../assets/portugal.svg';
import './countryswitchmobile.css';
import arrow from '../../assets/icons/arrow.png'

const CountrySwitcherMobile = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleCountrySelect = (country) => {
    if (country === 'angola') {
      window.location.href = 'https://ao.watchskyvision.com';
    } else {
      window.location.href = 'https://watchskyvision.com';
    }
  };

  return (
    <div className="country-switcher2">
      <button onClick={toggleDropdown} className="dropdown-button2">
        <img src={angola} alt="arrow" width={30}/>
        <img src={arrow} alt="arrow" width={20}/>
      </button>
      {dropdownOpen && (
        <div className="dropdown-menu2">
          <div className="dropdown-item2" onClick={() => handleCountrySelect('angola')}>
            <img src={angola} alt="Angola" className="flag" />
            <span>Angola</span>
          </div>
          <div className="dropdown-item2" onClick={() => handleCountrySelect('portugal')}>
            <img src={portugal} alt="Portugal" className="flag" />
            <span>Portugal</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default CountrySwitcherMobile;
